<template>
<div>
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true">
        <BuyChapterModal :novel="detailForModal" />
    </b-modal>
    <b-row cols="5" class="fiveNovelList">
        <b-col v-for="(novel,index) in novels" :key="index">
            <a class="text-decoration-none text-default-n" :href="'/novel/' + novel.novel_id" v-on:click="goToNovelOrChapter(novel, $event)">
                <b-card img-top tag="article" class="mt-1 mb-2 bookCardSize">
                    <b-card-text class="triangle" v-if="novel.chapter_price==0">
                        <div class="freeText">ฟรี</div>
                    </b-card-text>
                    <b-card-text class="small text-center noOfEpisode">
                        <div>{{novel.last_ep}}</div>
                    </b-card-text>
                    <div class="novelCover2" :style="{backgroundImage: 'url(\''+novel.novel_cover+'\') '}"></div>
                    <b-card-sub-title class="mb-2 novelName text-center">
                        <v-clamp autoresize :max-lines="2">{{novel.novel_title}}</v-clamp>
                    </b-card-sub-title>
                    <b-card-text class="small text-center text-muted">
                        ตอนที่ {{novel.last_ep}}
                    </b-card-text>
                </b-card>
            </a>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios'
import Login from "./../pages/Login"
import BuyChapterModal from "./../elements/BuyChapterModal"
import VClamp from 'vue-clamp'
import router from './../../router/index'
import {
    mapGetters
} from 'vuex'

export default {
    name: 'BookCard',
    props: ['novels', 'goTo'],
    data() {
        return {
            showLoginModal: false,
            showBuyChapterModal: false,
            detailForModal: {},
            userInfo: JSON.parse(localStorage.getItem('UserInfo'))
        }
    },
    components: {
        VClamp,
        Login,
        BuyChapterModal
    },
    mounted() {
        setTimeout(() => {
            this.checkMode()
        }, 10);
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    updated() {
        this.checkMode()
    },
    computed: {
        ...mapGetters({
            loginStatus: 'GET_LOGIN_STATUS',
            mode: 'GET_MODE'
        })
    },
    methods: {
        img_load_default_cover(event){
            //console.log("Image broken -> Load default")
            event.target.src = require("@/assets/default_cover.jpg")
        },
        goToNovelOrChapter(novel, e) {
            e.preventDefault();
            if (this.goTo == 'chapterPage') {
                if (novel.chapter_price > 0) {
                    if (this.loginStatus) {
                        this.checkBuyChapter(novel)
                    } else {
                        this.showLoginModal = true
                    }
                } else if (novel.chapter_price == 0) {
                    router.push("/novel/" + novel.novel_id)
                }
            } else if (this.goTo == 'novelPage') {
                router.push("/novel/" + novel.novel_id)
            }
            return false;
        },
        async checkBuyChapter(novel) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id', novel.chapter_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buycheck', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id);
                        } else if (res.data.status == 404) {
                            this.detailForModal = novel
                            this.detailForModal['member_gold'] = res.data.member_gold
                            this.showBuyChapterModal = true
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.add('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.add('darkmodeText')
            });
        }
    }
}
</script>

<style scoped>
.bookCardSize {
    border-color: #ffffff;
    border-radius: 0px;
    margin: auto;
}

.fiveNovelList {
    padding-top: 2%;
    padding-bottom: 1%;
}

.text-default-n{
    color: inherit;
}

.novelCover {
    width: 99%;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.card-body {
    padding: 10px;
    padding-bottom: 8px;
}

div.col {
    transform: translateZ(0px);
    transition-duration: .5s;
}

div.col:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.novelName {
    min-height: 38px;
    font-size: 16 !important;
    color: #000000 !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}

.noOfEpisode {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 16px;
    background-color: #EE806B;
    border-radius: 0px 0px 6px 6px;
    width: 26px;
    height: 28px;
    padding-top: 8px;
}

.triangle {
    border-style: solid;
    border-width: 0 34px 34px 0;
    border-color: transparent #849eb4 transparent transparent;
    position: absolute;
    right: -1px;
    top: -1px;
}

.freeText {
    position: absolute;
    right: -30px;
    top: 1px;
    color: #ffffff;
    font-size: 13px;
    transform: rotate(45deg);
}

img.card-image {
    width: 90% !important;
}

.darkmode {
    border-color: #35363A;
    background-color: #35363A;
}

.darkmodeText{
    color: #ffffff !important;
}
.novelCover2{
    width: 100%;
    padding-top: 144%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
