<template>
<div>
    <NovelListSlide v-if="novelTypes=='promotionNovel'&&$vssWidth<=540" :novels="lestestPromotionNovel" goTo="novelPage" />
    <NovelListSlide v-else-if="novelTypes=='translateNovel'&&$vssWidth<=540" :novels="lestestTranslateNovel" goTo="novelPage" />
    <NovelListSlide v-else-if="novelTypes=='createNovel'&&$vssWidth<=540" :novels="lestestCreateNovel" goTo="novelPage" />
    <NovelListSlide v-else-if="novelTypes=='allNovel'&&$vssWidth<=540" :novels="allNovel" goTo="novelPage" />
    <div class="lestestTranslateNovel" v-if="$vssWidth>540">
        <b-container class="bv-example-row">
            <BookCard v-if="novelTypes=='promotionNovel'" :novels="lestestPromotionNovel" goTo="novelPage" />
            <BookCard v-if="novelTypes=='translateNovel'" :novels="lestestTranslateNovel" goTo="novelPage" />
            <BookCard v-if="novelTypes=='createNovel'" :novels="lestestCreateNovel" goTo="novelPage" />
            <BookCard v-if="novelTypes=='allNovel'" :novels="allNovel" goTo="novelPage" />
        </b-container>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import BookCard from './../elements/BookCard.vue'
import NovelListSlide from './../elements/NovelListSlide.vue'

export default {
    name: "NovelList",
    props: ['novelTypes'],
    components: {
        BookCard,
        NovelListSlide
    },
    mounted() {
        this.checkMode()
        console.log('lestestCreateNovel')
        console.log(this.lestestCreateNovel)
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            lestestPromotionNovel: 'GET_LESTEST_PROMOTION_NOVEL',
            lestestTranslateNovel: 'GET_LESTEST_TRANSLATE_NOVEL',
            lestestCreateNovel: 'GET_LESTEST_CREATE_NOVEL',
            allNovel: 'GET_ALL_NOVEL',
            mode: 'GET_MODE'
        })
    },
    methods: {
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('lestestTranslateNovel').forEach(element => {
                element.classList.remove('darkmode')
            });
        },
        darkmode() {
            document.getElementsByClassName('lestestTranslateNovel').forEach(element => {
                element.classList.add('darkmode')
            });
        }
    }
}
</script>

<style scoped>
.lestestTranslateNovel {
    border: 2px solid #ffffff;
    width: 102%;
}

.darkmode {
    border: 2px solid #35363A;
}
</style>
