<template>
<div class="scope">
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showBuyChapterModal" :hide-header="true" :hide-footer="true">
        <BuyChapterModal :novel="detailForModal" />
    </b-modal>
    <carousel :navigationEnabled="true" :navigationClickTargetSize="3" :paginationSize="6" :perPage="2" :paginationPadding="3">
        <slide v-for="(novel,index) in novels" :key="index">
            <b-card img-top tag="article" class="mb-2 ml-1 mr-1 bookCardSize" @click="goToNovelOrChapter(novel)">
                <b-card-text class="triangle" v-if="novel.chapter_price==0">
                    <div class="freeText">ฟรี</div>
                </b-card-text>
                <b-card-text style="display:none;" class="small text-center noOfEpisode">
                    <div>{{novel.last_ep}}</div>
                </b-card-text>
                <div class="novelCover2" :style="{background: 'url(\''+novel.novel_cover+'\') no-repeat center center', backgroundSize: 'cover'}"></div>
                <b-card-sub-title class="mb-2 novelName">
                    <v-clamp autoresize :max-lines="1" style="fontSize:13px; textAlign:center">{{novel.novel_title}}</v-clamp>
                </b-card-sub-title>
                <b-card-text class="small text-center text-redpink">
                    ตอนที่ {{novel.last_ep}}
                </b-card-text>
            </b-card>
        </slide>
    </carousel>
</div>
</template>

<script>
import axios from 'axios'
import Login from "./../pages/Login"
import BuyChapterModal from "./../elements/BuyChapterModal"
import {
    mapGetters
} from 'vuex'
import {
    Carousel,
    Slide
} from 'vue-carousel';
import VClamp from 'vue-clamp'
import router from './../../router/index'

export default {
    name: 'BookCard',
    props: ['novels', 'goTo'],
    data() {
        return {
            showLoginModal: false,
            showBuyChapterModal: false,
            detailForModal: {}
        }
    },
    components: {
        Login,
        VClamp,
        Carousel,
        Slide,
        BuyChapterModal
    },
    mounted() {
        setTimeout(() => {
            this.checkMode()
        }, 100);
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    computed: {
        ...mapGetters({
            loginStatus: 'GET_LOGIN_STATUS',
            userInfo: 'GET_USER_INFO',
            mode: 'GET_MODE'
        })
    },
    methods: {
        img_load_default_cover(event){
            //console.log("Image broken -> Load default")
            event.target.src = require("@/assets/default_cover.jpg")
        },
        goToNovelOrChapter(novel) {
            if (this.goTo == 'chapterPage') {
                if (novel.chapter_price > 0) {
                    if (this.loginStatus) {
                        this.checkBuyChapter(novel)
                    } else {
                        this.showLoginModal = true
                    }
                } else if (novel.chapter_price == 0) {
                    router.push("/novel/" + novel.novel_id)
                }
            } else if (this.goTo == 'novelPage') {
                router.push("/novel/" + novel.novel_id)
            }
        },
        async checkBuyChapter(novel) {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('chapter_id', novel.chapter_id);
            try {
                await axios.post('https://api2.novelrealm.com/module/chapter&submodule=buycheck', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id);
                        } else if (res.data.status == 404) {
                            this.detailForModal = novel
                            this.detailForModal['member_gold'] = res.data.member_gold
                            this.showBuyChapterModal = true
                            this.showBuyModal = true
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementsByClassName('scope').forEach(element => {
                element.classList.remove('darkmodeBorder')
            });
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementsByClassName('scope').forEach(element => {
                element.classList.add('darkmodeBorder')
            });
            document.getElementsByClassName('bookCardSize').forEach(element => {
                element.classList.add('darkmode')
            });
            document.getElementsByClassName('novelName').forEach(element => {
                element.classList.add('darkmodeText')
            });
        }
    }
}
</script>

<style scoped>
.scope {
    border: 2px solid #ffffff;
}

.bookCardSize {
    border-color: #ffffff;
    border-radius: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.fiveNovelList {
    padding-top: 2%;
    padding-bottom: 1%;
}

.novelCover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.card-body {
    width: 121px;
    min-height: 197px;
    padding: 10px;
    margin: auto;
}

.novelName {
    font-size: 13 !important;
    color: #000000 !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}

.noOfEpisode {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 16px;
    background-color: #EE806B;
    border-radius: 0px 0px 6px 6px;
    width: 26px;
    height: 28px;
    padding-top: 8px;
}

.triangle {
    border-style: solid;
    border-width: 0 34px 34px 0;
    border-color: transparent #849eb4 transparent transparent;
    position: absolute;
    right: -1px;
    top: -1px;
}

.freeText {
    position: absolute;
    right: -30px;
    top: 1px;
    color: #ffffff;
    font-size: 13px;
    transform: rotate(45deg);
}

img.card-image {
    width: 90% !important;
}

/*  */

.VueCarousel {
    margin: 0px 22px;
}

/* Book CSS */
@media only screen and (max-width: 600px){
    .novelCover2{
        width: 100%;
        height: 190px; 
    }
    .card-body{
        margin: 0px;
        width: 100%;
    }
}
.text-redpink{
    color: #ee806b;
}


/* DarkMode */
.darkmode{
    background-color: #35363A;
    border: 2px solid #35363A;
}

.darkmodeBorder{
    border: 2px solid #35363A;
}

.darkmodeText{
    color: #ffffff !important;
}
</style>
